<template>
  <b-card>
    <validation-observer
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <b-row class="mb-1">
        <b-col cols="3">
          Payment method
        </b-col>
        <b-col cols="9">
          <b-form-select
            v-model="payment_method"
          >
            <b-form-select-option
              v-for="(option, idx) in paymentMethodOptions"
              :key="idx"
              :value="option.value"
            >
              {{ option.label }}
            </b-form-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row
        v-show="
          payment_method === allowMethods.PAYMENT_METHOD_PAYPAL
        "
        class="mb-1"
      >
        <b-col cols="3">
          Email
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="email"
            :rules="
              payment_method ===
                allowMethods.PAYMENT_METHOD_PAYPAL
                ? {
                  required: true,
                  email: true,
                  min: 3,
                  max: 100,
                }
                : ''
            "
          >
            <b-form-input
              v-model="email"
              :state="
                isValidated && errors.length > 0 ? false : null
              "
              placeholder="Email"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-show="
          payment_method ===
            allowMethods.PAYMENT_METHOD_ACH
        "
        class="mb-1"
      >
        <b-col cols="3">
          Account Number
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="account_number"
            :rules="
              payment_method ===
                allowMethods.PAYMENT_METHOD_ACH
                ? { required: true, min: 3, max: 100 }
                : ''
            "
          >
            <b-form-input
              v-model="account_number"
              :state="
                isValidated && errors.length > 0 ? false : null
              "
              placeholder="Account Number"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-show="
          payment_method ===
            allowMethods.PAYMENT_METHOD_ACH
        "
        class="mb-1"
      >
        <b-col cols="3">
          Routing Number
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="routing_number"
            :rules="
              payment_method ===
                allowMethods.PAYMENT_METHOD_ACH
                ? { required: true, min: 3, max: 100 }
                : ''
            "
          >
            <b-form-input
              v-model="routing_number"
              :state="
                isValidated && errors.length > 0 ? false : null
              "
              placeholder="Routing Number"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-show="
          payment_method ===
            allowMethods.PAYMENT_METHOD_INTENATIONAL_TRANSFER
        "
        class="mb-1"
      >
        <b-col cols="3">
          Account number
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            vid="account_number"
            name="account number"
            :rules="
              payment_method ===
                allowMethods.PAYMENT_METHOD_INTENATIONAL_TRANSFER
                ? { required: true, min: 3, max: 100 }
                : ''
            "
          >
            <b-form-input
              v-model="account_number"
              :state="
                isValidated && errors.length > 0 ? false : null
              "
              placeholder="Account number"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        v-show="
          payment_method ===
            allowMethods.PAYMENT_METHOD_INTENATIONAL_TRANSFER
        "
        class="mb-1"
      >
        <b-col cols="3">
          Swift
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            vid="swift"
            name="swift"
            :rules="
              payment_method === allowMethods.PAYMENT_METHOD_INTENATIONAL_TRANSFER
                ? { required: true, min: 3, max: 100 }
                : ''
            "
          >
            <b-form-input
              v-model="swift"
              :state="
                isValidated && errors.length > 0 ? false : null
              "
              placeholder="Swift"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row
        class="mb-1"
      >
        <b-col cols="3">
          Currency
        </b-col>
        <b-col cols="9">
          <b-form-radio-group
            id="radio-group-1"
            v-model="currency"
            :options="[
              {
                value: '$',
                text: 'USD',
              },
              {
                value: '€',
                text: 'EURO',
              }
            ]"
            name="curreny-options"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Minimum payment
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="minimum_payment"
            rules="greaterThanZero"
          >
            <minimum-payment
              v-if="alreadyMounted"
              v-model="minimum_payment"
              name="minimum_payment"
              :currency="currency"
              :payment-type="payment_method"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="
              isValidated = true;
              handleSubmit(submit);
            "
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="reset()"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'
import MinimumPayment from './MinimumPayment.vue'

extend('greaterThanZero', {
  computesRequired: true,
  validate: value => value > 0,
  message: 'Minimum paymen has to be greater than zero',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    MinimumPayment,
  },
  directives: {
    Ripple,
  },
  props: ['profile'],
  data() {
    return {
      payment_method: 1,
      email: '',
      routing_number: '',
      account_number: '',
      swift: '',
      bitcoin_address: '',
      currency: '$',
      minimum_payment: 50,
      // Options
      paymentMethodOptions: [],
      allowMethods: {
        PAYMENT_METHOD_PAYPAL: 1,
        PAYMENT_METHOD_ACH: 2,
        PAYMENT_METHOD_INTENATIONAL_TRANSFER: 3,
      },
      alreadyMounted: false,
      isValidated: false,
    }
  },
  computed: {
    activeUserInfo() {
      return this.profile
    },
  },
  mounted() {
    this.paymentMethodOptions = [
      {
        label: 'ACH',
        value: this.allowMethods.PAYMENT_METHOD_ACH,
      },
      {
        label: 'International Transfer',
        value: this.allowMethods.PAYMENT_METHOD_INTENATIONAL_TRANSFER,
      },
      {
        label: 'Paypal',
        value: this.allowMethods.PAYMENT_METHOD_PAYPAL,
      },
    ]
    this.fillOutUserData()
    this.alreadyMounted = true
  },
  methods: {
    submit() {
      this.showLoading()
      this.$http
        .put(
          '/api/profile-settings/update-payment-details',
          this.getPostData(),
        )
        .then(response => {
          this.$store.dispatch(
            'auth/updateProfile',
            response.data.profile,
          )
          this.$emit('refresh-profile')
          this.alertSuccess(response.data.message)
        })
        .catch(errors => {
          this.alertError(errors.response.data)
        })
        .finally(() => {
          this.hideLoading()
        })
    },
    fillOutUserData() {
      if (Object.keys(this.activeUserInfo).includes('payment_method')) {
        const filterMethod = this.paymentMethodOptions.filter(
          option => option.value === this.activeUserInfo.payment_method,
        )
        if (filterMethod.length === 1) {
          this.payment_method = filterMethod[0].value
        } else {
          this.payment_method = this.paymentMethodOptions[0].value
        }
      }
      if (Object.keys(this.activeUserInfo).includes('payment_details')) {
        if (
          typeof this.activeUserInfo.payment_details === 'object'
            && this.activeUserInfo.payment_details !== null
        ) {
          Object.keys(this.activeUserInfo.payment_details).forEach(
            key => {
              if (Object.keys(this.$data).includes(key)) {
                this.$data[key] = this.activeUserInfo.payment_details[key]
              }
            },
          )
        }
      }
      this.minimum_payment = this.profile.minimum_payment
      this.generateValidateFormResult()
    },
    reset() {
      this.isValidated = false
      this.fillOutUserData()
    },
    getPostData() {
      let postData = {}
      if (
        this.payment_method
          === this.allowMethods.PAYMENT_METHOD_PAYPAL
      ) {
        postData = {
          payment_method: this.allowMethods.PAYMENT_METHOD_PAYPAL,
          payment_details: JSON.stringify({
            email: this.email,
            currency: this.currency,
          }),
        }
      } else if (
        this.payment_method
          === this.allowMethods.PAYMENT_METHOD_ACH
      ) {
        postData = {
          payment_method:
          this.allowMethods.PAYMENT_METHOD_ACH,
          payment_details: JSON.stringify({
            account_number: this.account_number,
            routing_number: this.routing_number,
            currency: this.currency,
          }),
        }
      } else if (
        this.payment_method
          === this.allowMethods.PAYMENT_METHOD_INTENATIONAL_TRANSFER
      ) {
        postData = {
          payment_method:
          this.allowMethods.PAYMENT_METHOD_INTENATIONAL_TRANSFER,
          payment_details: JSON.stringify({
            account_number: this.account_number,
            swift: this.swift,
            currency: this.currency,
          }),
        }
      }
      postData.minimum_payment = this.minimum_payment
      return postData
    },
    generateValidateFormResult() {
    },
  },
}
</script>
