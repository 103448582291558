<template>
  <b-card>
    <b-row class="mb-2">
      <b-col>
        <b-form-checkbox
          id="mailNewsLetterSwitch"
          v-model="mail_newsletter"
          name="check-button"
          switch
          inline
        >
          <div style="font-size: 1rem; margin-left: 3rem;">
            PrimeSurveys Newsletter
          </div>
          <div style="font-size: 0.75rem; margin-left: 3rem;">
            General information, Technology Updates and Recommendations to improve your integration
          </div>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-form-checkbox
          id="mailWeekEarningReportSwitch"
          v-model="mail_week_earning_report"
          name="check-button"
          switch
          inline
        >
          <div style="font-size: 1rem; margin-left: 3rem;">
            Weekly Earnings Report
          </div>
          <div style="font-size: 0.75rem; margin-left: 3rem;">
            Weekly Report on your income, active users and more useful statistics
          </div>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row class="mb-2">
      <b-col>
        <b-form-checkbox
          id="mailPaymentStatusUpdateSwitch"
          v-model="mail_payment_status_update"
          name="check-button"
          switch
          inline
        >
          <div style="font-size: 1rem; margin-left: 3rem;">
            Payment Status Updates
          </div>
          <div style="font-size: 0.75rem; margin-left: 3rem;">
            Information on each status update of your payments
          </div>
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mt-2 mr-1"
          @click="submit()"
        >
          Save changes
        </b-button>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          type="reset"
          class="mt-2"
          @click="fillOutUserData()"
        >
          Reset
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  props: ['profile'],
  data() {
    return {
      mail_newsletter: false,
      mail_week_earning_report: false,
      mail_payment_status_update: false,
    }
  },
  mounted() {
    this.fillOutUserData()
  },
  methods: {
    fillOutUserData() {
      this.mail_newsletter = !!this.profile.mail_newsletter
      this.mail_week_earning_report = !!this.profile.mail_week_earning_report
      this.mail_payment_status_update = !!this.profile.mail_payment_status_update
    },
    submit() {
      this.showLoading()
      this.$http
        .put('/api/profile-settings/update-mail-settings', {
          mail_newsletter: this.mail_newsletter ? 1 : 0,
          mail_week_earning_report: this.mail_week_earning_report ? 1 : 0,
          mail_payment_status_update: this.mail_payment_status_update ? 1 : 0,
        })
        .then(response => {
          this.hideLoading()
          this.$store.dispatch('auth/updateProfile', response.data.profile)
          this.$emit('refresh-profile')
          this.alertSuccess(response.data.message)
        })
        .catch(errors => {
          this.hideLoading()
          this.alertError(errors.response.data)
        })
    },
  },
}
</script>
