<template>
  <b-card>
    <!-- form -->
    <validation-observer
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <b-row class="mb-1">
        <b-col cols="3">
          Old Password
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="old password"
            :rules="{ required: true, min: 8, max: 15 }"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                rules="required|min:8|max:15"
                :type="passwordFieldTypeOld"
                placeholder="Old Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="3">
          New password
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            vid="newpassword"
            name="new password"
            rules="required|min:8|max:15"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-new-password"
                ref="new_password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                name="new-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="3">
          Retype new password
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            vid="confirm"
            name="retype new password"
            rules="required|min:8|max:15|password:@newpassword"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                name="retype-password"
                placeholder="New Password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
            @click="
              isValidated = true;
              handleSubmit(submit);
            "
          >
            Save changes
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
  extend,
} from 'vee-validate/dist/vee-validate.full'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match',
})

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isValidated: false,
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  methods: {
    reset() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
    },
    submit() {
      this.showLoading()
      this.$http
        .put('/api/profile-settings/update-password', {
          old_password: this.passwordValueOld,
          new_password: this.newPasswordValue,
        })
        .then(response => {
          this.reset()
          this.hideLoading()
          this.$store.dispatch(
            'auth/updateProfile',
            response.data.profile,
          )
          this.$emit('refresh-profile')
          this.alertSuccess(response.data.message)
        })
        .catch(errors => {
          this.hideLoading()
          this.alertError(errors.response.data)
        })
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password'
        ? 'text'
        : 'password'
    },
  },
}
</script>
