<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-form-select
          v-show="isOptionField"
          v-model="selectAmount"
        >
          <b-form-select-option
            v-for="(item, index) in minimumPaymentOptions"
            :key="index"
            :value="item.value"
          >
            {{ item.label }} {{ currencySymbol }}
          </b-form-select-option>
        </b-form-select>
        <b-form-input
          v-show="!isOptionField"
          v-model="inputAmount"
          type="number"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

const PAYMENT_METHOD_PAYPAL = 1
const PAYMENT_METHOD_ACH = 2
const PAYMENT_METHOD_INTENATIONAL_TRANSFER = 3
const PAYMENT_METHOD_BITCOIN = 4

export default {
  props: [
    'value',
    'currency',
    'paymentType',
  ],
  data() {
    return {
      minimumPaymentOptions: [
        { value: 50, label: 50 },
        { value: 100, label: 100 },
        { value: 200, label: 200 },
        { value: 500, label: 500 },
        { value: 1000, label: 1000 },
        { value: 2000, label: 2000 },
        { value: 5000, label: 5000 },
        { value: 10000, label: 10000 },
      ],
      selectAmount: 0,
      inputAmount: 0.001,
    }
  },
  computed: {
    isOptionField() {
      return [
        PAYMENT_METHOD_PAYPAL,
        PAYMENT_METHOD_ACH,
        PAYMENT_METHOD_INTENATIONAL_TRANSFER,
      ].indexOf(parseInt(this.paymentType)) >= 0
    },
    currencySymbol() {
      if (parseInt(this.paymentType) === PAYMENT_METHOD_BITCOIN) {
        return 'btc'
      }

      switch (this.currency) {
        case 'usd':
          return '$'
        case 'euro':
          return '€'
        default:
          return '$'
      }
    },
    getValue() {
      if (this.isOptionField) {
        return parseInt(this.selectAmount)
      }
      return parseFloat(this.inputAmount)
    },
  },
  watch: {
    selectAmount() {
      this.$emit('input', this.getValue)
    },
    inputAmount() {
      this.$emit('input', this.getValue)
    },
    paymentType() {
      this.$emit('input', this.getValue)
    },
  },
  mounted() {
    if (this.isOptionField) {
      const filterOption = this.minimumPaymentOptions.filter(value => value.value === parseInt(this.value))
      if (filterOption.length > 0) {
        this.selectAmount = filterOption[0].value
      } else {
        this.selectAmount = 50
      }
    } else {
      this.inputAmount = this.value
    }
  },
}
</script>

<style>

</style>
