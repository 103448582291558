<template>
  <b-tabs>

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Account</span>
      </template>
      <user-settings-general
        v-if="isReady"
        :profile="profile"
        :countries="countries"
        @refresh-profile="refreshProfile()"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Security</span>
      </template>

      <user-settings-change-password
        v-if="isReady"
        :profile="profile"
        @refresh-profile="refreshProfile()"
      />
    </b-tab>
    <!--/ change password tab -->

    <!-- info -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Payment</span>
      </template>

      <user-settings-payment
        v-if="isReady"
        :profile="profile"
        @refresh-profile="refreshProfile()"
      />
    </b-tab>

    <!-- social links -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LinkIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">E-Mail Reports</span>
      </template>
      <user-settings-email-report
        v-if="isReady"
        :profile="profile"
        @refresh-profile="refreshProfile()"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import UserSettingsGeneral from './UserSettingsGeneral.vue'
import UserSettingsChangePassword from './UserSettingsChangePassword.vue'
import UserSettingsPayment from './UserSettingsPayment.vue'
import UserSettingsEmailReport from './UserSettingsEmailReport.vue'

export default {
  components: {
    UserSettingsGeneral,
    UserSettingsChangePassword,
    UserSettingsPayment,
    UserSettingsEmailReport,
    // CustomVsTab,
  },
  data() {
    return {
      profile: null,
      isReady: false,
      countries: [],
    }
  },
  computed: {
    isSmallerScreen() {
      return this.$store.state.windowWidth < 768
    },
  },
  created() {
    this.showLoading()
    this.$http.get('/api/profile-settings/get').then(response => {
      this.profile = response.data.profile
      this.countries = response.data.countries
      this.$store.dispatch('auth/updateProfile', response.data.profile)
      this.refreshProfile()
      this.isReady = true
    }).catch(errors => {
      this.alertError(errors.response.data.errors)
      this.isReady = true
    })
      .finally(() => {
        this.hideLoading()
      })
  },
  methods: {
    refreshProfile() {
      this.profile = this.$store.getters['auth/appActiveUser']
    },
  },
}
</script>
