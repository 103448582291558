<template>
  <b-card>
    <!-- Info -->
    <validation-observer
      ref="form"
      v-slot="{ handleSubmit }"
    >
      <b-row class="mb-1">
        <b-col cols="3">
          Email
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="email"
            :rules="{ required: true, email: true, min: 3, max: 100 }"
          >
            <b-form-input
              v-model="email"
              :state="isValidated && errors.length > 0 ? false : null"
              placeholder="Email"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Company
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="company"
            :rules="{ required: true, min: 3, max: 100 }"
          >
            <b-form-input
              v-model="company"
              :state="isValidated && errors.length > 0 ? false : null"
              placeholder="Company"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          First Name
        </b-col>
        <b-col cols="9">
          <validation-provider
            #default="{ errors }"
            name="first name"
            :rules="{ required: true, min: 3, max: 100 }"
          >
            <b-form-input
              v-model="first_name"
              :state="isValidated && errors.length > 0 ? false : null"
              placeholder="First name"
            />
            <small
              v-if="isValidated && errors.length > 0"
              class="text-danger"
            >{{ errors[0] }}</small>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Last Name
        </b-col>
        <b-col cols="9">
          <b-form-input
            v-model="last_name"
            placeholder="Last name"
          />
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col cols="3">
          Address Line 1
        </b-col>
        <b-col cols="9">
          <b-form-input
            v-model="address_line1"
            placeholder="Address Line 1"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Address Line 2
        </b-col>
        <b-col cols="9">
          <b-form-input
            v-model="address_line2"
            placeholder="Address Line 2"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Zip Code
        </b-col>
        <b-col cols="9">
          <b-form-input
            v-model="zip_code"
            placeholder="Zip Code"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          City
        </b-col>
        <b-col cols="9">
          <b-form-input
            v-model="city"
            placeholder="City"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Country
        </b-col>
        <b-col cols="9">
          <b-form-select
            v-model="country"
            :options="countries"
            text-field="name"
            value-field="code"
            placeholder="Country"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Phone Number
        </b-col>
        <b-col cols="9">
          <b-form-input
            v-model="phone_number"
            placeholder="Phone Number"
          />
        </b-col>
      </b-row>
      <b-row class="mb-1">
        <b-col cols="3">
          Skype Username
        </b-col>
        <b-col cols="9">
          <b-form-input
            v-model="skype"
            placeholder="Skype Username"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="isValidated = true; handleSubmit(saveGeneralInformation)"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="reset()"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-card>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate/dist/vee-validate.full'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: ['profile', 'countries'],
  data() {
    return {
      isValidated: false,
      first_name: '',
      last_name: '',
      email: '',
      company: '',
      address_line1: '',
      address_line2: '',
      zip_code: '',
      city: '',
      country: '',
      phone_number: '',
      skype: '',
    }
  },
  computed: {
    validateForm() {
      return (
        !this.errors.any()
          && !this.first_name !== ''
          && !this.company !== ''
      )
    },
  },
  mounted() {
    this.fetchDataFromStore()
  },
  methods: {
    saveGeneralInformation() {
      this.showLoading()
      this.$http
        .put('/api/profile-settings/update-general-information', {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          company: this.company,
          address_line1: this.address_line1,
          address_line2: this.address_line2,
          zip_code: this.zip_code,
          city: this.city,
          country: this.country,
          phone_number: this.phone_number,
          skype: this.skype,
        })
        .then(response => {
          this.$store.dispatch(
            'auth/updateProfile',
            response.data.profile,
          )
          this.$emit('refresh-profile')
          this.hideLoading()
          this.alertSuccess(response.data.message)
        })
        .catch(() => {
          this.hideLoading()
        })
    },
    reset() {
      this.isValidated = false
      this.fetchDataFromStore()
    },
    fetchDataFromStore() {
      this.first_name = this.profile.first_name
      this.last_name = this.profile.last_name
      this.email = this.profile.email
      this.company = this.profile.company
      this.address_line1 = this.profile.address_line1
      this.address_line2 = this.profile.address_line2
      this.zip_code = this.profile.zip_code
      this.city = this.profile.city
      this.country = this.profile.country
      this.phone_number = this.profile.phone_number
      this.skype = this.profile.skype
    },
  },
}
</script>
